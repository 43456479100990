import "../sass/main.scss";
import "./navigation";
import "./home";
import "./utils";
import "./video";
import "./slide_ecran";

import SmoothScroll from "smooth-scroll";

jQuery(document).ready(function ($) {
  // ================================================== LOADER
  $(window).load(function () {
    $("#status").fadeOut();
    $("#preloader").delay(350).fadeOut(500);
    $("body").delay(350).css({
      overflow: "visible",
    });
  });

  // ================================================== CLOSE ALERT
  $(".close").click(function () {
    $(".alert").hide();
  });

  // ================================================== SMOOTH
  var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
  });
  // ================================================== POPUP
  $(".cards--animations-spectacles .card").each(function () {
    var $this = $(this);

    $this.find(".btn").click(function () {
      $this.find(".popup_introtext").addClass("popup_introtext-active");
    });
  });

  $(".close__introtext").click(function () {
    $(".popup_introtext").removeClass("popup_introtext-active");
  });
});
