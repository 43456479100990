import Swiper from "swiper/bundle";

jQuery(function ($) {
  // ------------------------------------------- SWIPER
  $(window).load(function () {
    const swiper = new Swiper(".swiper__ecran", {
      slidesPerView: 1,
      speed: 700,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      loop: true,
    });
  });
});
