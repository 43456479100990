jQuery(function ($) {
  //------------------------------------------------------ Bouton menu click
  $("#menu__btn").on("click", function () {
    $(".header__menu").toggleClass("header__menu--active");
    $(this).toggleClass("menu__btn--active");
    $("body").toggleClass("menu__body--active");
  });

  $(document).on("mouseup", function (e) {
    if (!e.target.closest(".header__menu")) {
      $(".header__menu").removeClass("header__menu--active");
      $("#menu__btn").removeClass("menu__btn--active");
      $("body").removeClass("menu__body--active");
    }
  });
  // ------------------------------------------- nav child
  $(".parent").click(function () {
    $(".nav-child").removeClass("nav-child--active");
    $(this).find(".nav-child").toggleClass("nav-child--active");
  });

  $(document).on("mouseup", function (e) {
    if (!e.target.closest(".parent")) {
      $(".nav-child").removeClass("nav-child--active");
    }
  });
});
