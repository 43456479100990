import Swiper from "swiper/bundle";

jQuery(function ($) {
  $(".date__buttons .btn").each(function () {
    var $this = $(this);
    $this.click(function () {
      $(".date__buttons .btn").removeClass("active");
      $this.addClass("active");
    });
  });

  // ------------------------------------------- SWIPER
  $(window).load(function () {
    const swiper = new Swiper(".swiper__animations", {
      slidesPerView: 1,
      speed: 700,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    const swiper2 = new Swiper(".swiper__spectacles", {
      slidesPerView: 1,
      loop: true,
      speed: 700,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    const swiper3 = new Swiper(".swiper__tarifs", {
      slidesPerView: 1,
      loop: true,
      speed: 700,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    const swiper4 = new Swiper(".swiper__contact", {
      slidesPerView: 1,
      loop: true,
      speed: 700,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    const swiper5 = new Swiper(".swiper__details", {
      slidesPerView: 1,
      loop: true,
      speed: 700,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  });
});
