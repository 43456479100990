import Plyr from "plyr";

jQuery(document).ready(function ($) {
  $(window).load(function () {
    // const player = Plyr.setup(".player", {
    //   controls: [
    //     "play-large",
    //     "play",
    //     "progress",
    //     "mute",
    //     "volume",
    //     "fullscreen",
    //   ],
    // });

    let player = Array.from(document.querySelectorAll(".player")).map(
      (p) =>
        new Plyr(p, {
          controls: [
            "play-large",
            "play",
            "progress",
            "mute",
            "volume",
            "fullscreen",
          ],
        })
    );

    $(".card").each(function (i) {
      var $this = $(this);
      $this.find(".card__play").click(function () {
        $this.find(".modal").addClass("modal--active");
        $("body").addClass("body__modal--active");
        player[i].play();
      });

      $this.find(".modal__close").click(function () {
        $this.find(".modal").removeClass("modal--active");
        $("body").removeClass("body__modal--active");
        player[i].stop();
      });
    });
  });
});
