jQuery(function ($) {
  // -------------------------------------- bouton top page
  window.addEventListener("scroll", () => {
    const position = window.pageYOffset;
    const btnTop = document.querySelector(".btn__top");

    if (position > 300) {
      btnTop.classList.add("btn__top--active");
    } else {
      btnTop.classList.remove("btn__top--active");
    }

    // btnTop.addEventListener("click", () => {
    //   document.body.scrollTop = 0;
    //   document.documentElement.scrollTop = 0;
    // });
  });

  // ----------------------------------- ancre vers module au rechargement de page
  $(".jmoddiv").each(function () {
    var moduleEditUrl = $(this).data("jmodediturl");
    var moduleTip = "modifier le module";
    var moduleTarget = $(this).data("target");

    $(this)
      .prepend(
        '<a class="btn--frontedit" href="#" target="' +
          moduleTarget +
          '"><i class="fas fa-edit"></i>Éditer</a>'
      )
      .find(".btn--frontedit")
      .attr("href", moduleEditUrl + "#module-form")
      .attr("title", moduleTip);
  });

  // ----------------------------------- POPUP MESSAGE

  $(".popup__close").click(function () {
    $(".popup__message").hide();
  });

  $(document).on("mouseup", function (e) {
    if (!e.target.closest(".popup__message")) {
      $(".popup__message").hide();
    }
  });
});

// var c,
// currentScrollTop = 0;

// window.addEventListener("scroll", () => {
// const position = window.pageYOffset;
// const btnTop = document.querySelector(".btn__top");
// let b = 150;
// // const posActive = document.querySelector(".");

// currentScrollTop = position;

// if (c < currentScrollTop && position > b + b) {
//   btnTop.classList.add("btn__top--active");
// } else if (c > currentScrollTop && !(position <= b)) {
//   btnTop.classList.remove("btn__top--active");
// }
// if (currentScrollTop > 350) {
//   btnTop.classList.add("btn__top--opc");
// } else {
//   btnTop.classList.remove("btn__top--opc");
// }

// c = currentScrollTop;
// });
